// import Vue from 'vue/dist/vue.esm';

function vueRender({ loading }) {
  // return new Vue({
  //   template: `
  //     <div id="subapp-container">
  //       <h4 v-if="loading" class="subapp-loading">Loading...</h4>
  //       <div id="subapp-viewport"></div>
  //     </div>
  //   `,
  //   el: '#subapp-container',
  //   data() {
  //     return {
  //       loading,
  //     };
  //   },
  // });
  const parent = document.getElementById('subapp-container');
  if (!document.getElementById('subapp-viewport')) {
    const div = document.createElement('div');
    div.id = 'subapp-viewport';
    parent.append(div);
  }
  if (!document.getElementById('subapp-loading')) {
    const loading = document.createElement('div');
    loading.innerHTML = 'Loading...';
    loading.className = 'subapp-loading';
    loading.id = 'subapp-loading';
    loading.style.cssText = `position: fixed;width: 100%;
      height: 50%;text-align: center;font-size: 18px;color:#ccc;
      top: 30%;
    `
    parent.append(loading);
  }
  document.getElementById('subapp-loading').style.display = loading ? 'block' : 'none';
}

export default function render({ loading }) {
  vueRender({ loading });
}
